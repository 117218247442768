import { SanityTypography } from "typings/graphql"

export type SanityTypographyKey = keyof SanityTypography

type TypographyCouple = {
  name: string
  sanityKey: SanityTypographyKey
}

export const sanityTypographyCouples: TypographyCouple[] = [
  { name: "h1", sanityKey: "h1Type" },
  { name: "h2", sanityKey: "h2Type" },
  { name: "h3", sanityKey: "h3Type" },
  { name: "h4", sanityKey: "h4Type" },
  { name: "h5", sanityKey: "h5Type" },
  { name: "h6", sanityKey: "h6Type" },
  { name: "body", sanityKey: "body1" },
  { name: "bodySmall", sanityKey: "body2" },
  { name: "caption", sanityKey: "caption" },
  { name: "blogHeader", sanityKey: "blogHeader" },
]
