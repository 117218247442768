export const asyncSleep = (ms: number): Promise<void> =>
  new Promise(resolve => setTimeout(resolve, ms))

export const waitFor = async (
  waitForCallback: () => boolean,
  interval = 500,
  timeout = 10000
): Promise<void> => {
  if (waitForCallback()) {
    return
  }

  const remainingTimeout = timeout - interval

  if (remainingTimeout < 0) {
    throw new Error("timeout exceeded")
  } else {
    await asyncSleep(interval)
    await waitFor(waitForCallback, interval, remainingTimeout)
  }
}
