import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { Notification } from "typings/modules"

const initialState: Notification = {
  message: "",
  theme: "success",
  autoHide: true,
}

const notificationSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    showNotification: (state, action: PayloadAction<Notification>) => {
      const { message, theme, autoHide } = action.payload
      state.message = message
      state.theme = theme || "success"
      if (typeof autoHide !== "undefined") {
        state.autoHide = autoHide
      }
    },
    hideNotification: state => {
      state.message = initialState.message
      state.autoHide = true
    },
  },
})

export const { showNotification, hideNotification } = notificationSlice.actions

export const notificationReducer = notificationSlice.reducer
