import { GlobalConfigs } from "./brand-config"

export const globalConfigs: GlobalConfigs = {
  dev: {
    launchDarklyClientSideId: "62227d7c35775a149a1015c2",
    auth0: {
      metaDataNamespace: "https://staging.patternbrands.co/",
      audience: "https://staging.patternbrands.co/api",
      jwtIssuer: "https://accounts-staging.patternbrands.co/",
      managementApi: {
        domain: "dev-5yegho2l.us.auth0.com",
        clientId: "P7X4WG743ymNEkw0Z92wdcKYJ7lhmjd5",
        clientSecret:
          "kcOo9wm6Dk_Hn1mvPRNrfWgYYJIElNFGRhdyyy43goQ6EiHvPtseyjBsF7ru-BAv",
      },
    },
  },
  production: {
    launchDarklyClientSideId: "62227d7c35775a149a1015c3",
    auth0: {
      metaDataNamespace: "https://patternbrands.com/",
      audience: "https://patternbrands.com/api",
      jwtIssuer: "https://account.patternbrands.com/",
      managementApi: {
        domain: "patternbrands-prod.us.auth0.com",
        clientId: "8Waa6fUFVEadvF465fufbd3tcjtPP3Dx",
        clientSecret:
          "AOwByQ2X5MuKqWo1FGDKXemjCHvo_kUkn-2sLNYjmX26i_teHvvzKLiZFMtBex9e",
      },
    },
  },
}
