import { pbConfigs } from "./pb-config"
import { osConfigs } from "./os-config"
import { girConfigs } from "./gir-config"
import { epConfigs } from "./ep-config"
import { lfConfigs } from "./lf-config"
import { ydConfigs } from "./yd-config"
import { pkConfigs } from "./pk-config"
import { globalConfigs } from "./global-config"
import {
  BrandConfig,
  BrandConfigs,
  ConfigEnvironment,
  GlobalConfig,
} from "./brand-config"
import { BRAND_KEY } from "./brands"

export const env = (process.env.GATSBY_ENV as ConfigEnvironment) || "dev"
export const brand = process.env.GATSBY_BRAND || "pb"

export const brands: Record<BRAND_KEY, BrandConfigs> = {
  pb: pbConfigs,
  os: osConfigs,
  gir: girConfigs,
  ep: epConfigs,
  lf: lfConfigs,
  yd: ydConfigs,
  pk: pkConfigs,
}

const brandConfig = brands[brand as BRAND_KEY][env]
const globalConfig = globalConfigs[env]

export const Config: BrandConfig & GlobalConfig = {
  ...brandConfig,
  ...globalConfig,
}
