import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import {
  MAX_UNDOS_ALLOWED,
  HALF_MOON_CIRCLE_CENTER_HEIGHT,
  smallMatDimensions,
  HALF_MOON_CIRCLE_STROKE_WIDTH,
} from "components/TileMat/TileMat.constants"
import {
  TileMatColorSettings,
  Tiles,
  TileDimensions,
  TileMatDimensions,
  TileMatUserAction,
  Tile,
  TileVariantColor,
  TileMatShapeTypes,
} from "components/TileMat/TileMat.types"

type TileMatToolState = {
  allTileMatColorSettings: Record<string, TileMatColorSettings>
  selectedColorSettings: TileMatColorSettings
  tiles: Tiles
  isDrawing: boolean
  tileDimensions: TileDimensions
  tileMatDimensions: TileMatDimensions
  drawColorHex: string
  userActions: TileMatUserAction[]
  currentActionIndex: number
  containerWidth: number | undefined
  availableVariantColors: TileVariantColor[]
  colors: TileVariantColor[]
}

const initialState: TileMatToolState = {
  allTileMatColorSettings: {},
  selectedColorSettings: null,
  tiles: [],
  isDrawing: false,
  tileDimensions: null,
  tileMatDimensions: smallMatDimensions,
  drawColorHex: null,
  userActions: [],
  currentActionIndex: 0,
  containerWidth: null,
  availableVariantColors: null,
  colors: null,
}

const tileMatToolSlice = createSlice({
  name: "tileMatTool",
  initialState,
  reducers: {
    initialize: (
      state,
      action: PayloadAction<{
        containerWidth: number
        colors?: TileVariantColor[]
        allTileMatColorSettings?: Record<string, TileMatColorSettings>
      }>
    ) => {
      const { containerWidth, colors, allTileMatColorSettings } = action.payload

      state.containerWidth = containerWidth
      state.availableVariantColors = colors ?? state.availableVariantColors
      state.colors = colors ?? state.colors

      if (allTileMatColorSettings) {
        state.allTileMatColorSettings = allTileMatColorSettings
        state.selectedColorSettings = allTileMatColorSettings.white
        state.drawColorHex = state.selectedColorSettings.defaultDrawColorHex
      }

      // reset user actions since undoing after tile mat resize causes issues
      // with current implementation
      state.userActions = []

      prependDefaultTileColorIfNecessary(state)
      initCanvas(state)
      recordAction(state)
    },
    drawFinished: (
      state,
      action: PayloadAction<{ x: number; y: number; hexCode: string }[]>
    ) => {
      action.payload.forEach(({ x, y, hexCode }) => {
        state.tiles[x][y].hexCode = hexCode
      })

      recordAction(state)
    },
    changeTileMatColor: (
      state,
      action: PayloadAction<{
        tileMatColorSettings: TileMatColorSettings
      }>
    ) => {
      const { tileMatColorSettings } = action.payload

      state.selectedColorSettings = tileMatColorSettings
      state.drawColorHex = tileMatColorSettings.defaultDrawColorHex
      state.colors = state.availableVariantColors

      prependDefaultTileColorIfNecessary(state)
      state.tiles = createMatTiles(state)

      recordAction(state)
    },
    changeTileMatSize: (
      state,
      action: PayloadAction<{
        tileMatDimensions: TileMatDimensions
      }>
    ) => {
      const { tileMatDimensions } = action.payload

      state.tileMatDimensions = tileMatDimensions

      initCanvas(state)
      recordAction(state)
    },
    changeDrawColor: (state, action: PayloadAction<{ colorHex: string }>) => {
      state.drawColorHex = action.payload.colorHex
    },
    undo: state => {
      applyAction(state, state.currentActionIndex - 1)
    },
    redo: state => {
      applyAction(state, state.currentActionIndex + 1)
    },
    reset: state => {
      const newTiles = createMatTiles(state, false)
      state.tiles = newTiles

      recordAction(state)
    },
  },
})

const initCanvas = (state: TileMatToolState) => {
  const { tileMatDimensions, containerWidth } = state

  const canvasWidth = containerWidth
  const diam =
    canvasWidth / ((tileMatDimensions.height + 0.5) * 1.4330127018922196)

  const canvasHeight =
    (tileMatDimensions.width + 3.5) * (diam * 0.4330127018922193)

  const newTileDimensions = {
    diam,
    border: diam,
    radius: Math.sqrt((diam / 2) ** 2 - (diam / 4) ** 2),
    canvasWidth,
    canvasHeight,
  }

  state.tileDimensions = newTileDimensions
  state.tiles = createMatTiles(state)
}

function createMatTiles(
  state: TileMatToolState,
  keepCurrentTileColors = true
): Tiles {
  const {
    tileMatDimensions,
    tileDimensions,
    selectedColorSettings,
    tiles,
  } = state
  const { diam, border, radius } = tileDimensions
  const newTiles = []

  for (let x = 0; x < tileMatDimensions.height; x++) {
    const tileRow: Tile[] = []

    for (let y = 0; y < tileMatDimensions.width; y++) {
      let displayTile = true
      let disableTile = false
      // Display this hex?
      if (x === tileMatDimensions.height - 1 && !(y % 2)) {
        displayTile = false
      }

      // odd numbered rows are offset left
      const offset = ((y + 1) % 2) * ((diam + radius) / 2)
      const xPx = 2.0 + (border + (x * (diam + radius) + offset))
      const yPx = 0.0 + (border + y * radius)

      if (tileMatDimensions.shape === TileMatShapeTypes.HalfMoon) {
        const strokeWidth = diam * HALF_MOON_CIRCLE_STROKE_WIDTH
        const circleCenterY = radius * HALF_MOON_CIRCLE_CENTER_HEIGHT
        const circleCenterX = tileDimensions.canvasWidth / 2
        const arcRadius = circleCenterX - radius - strokeWidth
        const pointDistanceFromCenter = Math.sqrt(
          (xPx - circleCenterX) ** 2 + (yPx - circleCenterY) ** 2
        )

        const isIntersecting =
          pointDistanceFromCenter > arcRadius ||
          (pointDistanceFromCenter < arcRadius &&
            arcRadius - pointDistanceFromCenter < diam * 0.25)

        // disable all tiles outside of the arc
        if (
          y === 0 ||
          x === tileMatDimensions.height - 1 ||
          (x === 0 && y % 2 !== 0) ||
          (y > 18 && isIntersecting)
        ) {
          disableTile = true
        }
      }

      const hexCode =
        displayTile &&
        keepCurrentTileColors &&
        tiles?.[x]?.[y]?.hexCode !== selectedColorSettings.tileColorHex
          ? tiles?.[x]?.[y]?.hexCode
          : undefined

      // create a point
      const point: Tile = {
        x: xPx,
        y: yPx,
        displayTile,
        disableTile,
        hexCode,
      }

      tileRow.push(point)
    }

    newTiles.push(tileRow)
  }

  return newTiles
}

const prependDefaultTileColorIfNecessary = (state: TileMatToolState) => {
  const { selectedColorSettings } = state

  const currentColorsHasTileColor = state.colors.some(
    color => color.hexCode === selectedColorSettings.tileColorHex
  )

  if (!currentColorsHasTileColor) {
    // Add the default tile color so users can clear out tiles

    state.colors = [
      {
        name: "Default",
        hexCode: selectedColorSettings.tileColorHex,
        variant: null,
        availableForSale: false,
        price: 0,
      },
      ...state.availableVariantColors,
    ]
  }
}

const recordAction = (state: TileMatToolState) => {
  // Reduce user actions array to current action position
  const newUserActions = state.userActions.slice(
    0,
    state.currentActionIndex + 1
  )

  newUserActions.push({
    tileMatDimensions: state.tileMatDimensions,
    colorSettings: state.selectedColorSettings,
    tiles: JSON.parse(JSON.stringify(state.tiles)),
  })

  if (newUserActions.length > MAX_UNDOS_ALLOWED) {
    newUserActions.shift()
  }

  state.currentActionIndex = newUserActions.length - 1
  state.userActions = newUserActions
}

const applyAction = (state: TileMatToolState, newActionIndex: number) => {
  const newState = state.userActions[newActionIndex]

  state.currentActionIndex = newActionIndex
  state.tiles = JSON.parse(JSON.stringify(newState.tiles))

  if (
    newState.colorSettings.backgroundColorHex !==
      state.selectedColorSettings.backgroundColorHex ||
    newState.colorSettings.tileColorHex !==
      state.selectedColorSettings.tileColorHex
  ) {
    state.selectedColorSettings = newState.colorSettings
    state.drawColorHex = newState.colorSettings.defaultDrawColorHex
    state.colors = state.availableVariantColors

    prependDefaultTileColorIfNecessary(state)
    state.tiles = createMatTiles(state)
  }

  if (
    newState.tileMatDimensions.height !== state.tileMatDimensions.height ||
    newState.tileMatDimensions.width !== state.tileMatDimensions.width
  ) {
    state.tileMatDimensions = newState.tileMatDimensions

    initCanvas(state)
  }
}

export const {
  initialize,
  drawFinished,
  undo,
  redo,
  reset,
  changeTileMatColor,
  changeTileMatSize,
  changeDrawColor,
} = tileMatToolSlice.actions

export const tileMatToolReducer = tileMatToolSlice.reducer
