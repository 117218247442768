import { createSlice, PayloadAction } from "@reduxjs/toolkit"

type IsOpen = { isOpen: boolean }
type State = {
  butterBarShown: boolean
}

const initialState: State = { butterBarShown: false }

const headerSlice = createSlice({
  name: "header",
  initialState,
  reducers: {
    setButterBarOpen: (state, action: PayloadAction<IsOpen>) => {
      state.butterBarShown = action.payload.isOpen
    },
  },
})

export const { setButterBarOpen } = headerSlice.actions

export const headerReducer = headerSlice.reducer
