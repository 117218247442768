import React, { ReactNode } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Helmet } from "react-helmet"
import { Query } from "typings/graphql"
import { sanityTypographyCouples } from "./typographyCouples"
import { css } from "styled-components"

type Props = {
  children: ReactNode
}

export type FontBlock = {
  name: string
  woffUrl: string
  woff2Url: string
  fontWeight?: string
  italic?: boolean
}

const staticFontQuery = graphql`
  fragment FontWoffs on SanityTypographyOption {
    typeface {
      _id
      fontName
      fontVariants {
        weight
        italic
        woff {
          asset {
            url
          }
        }
        woff2 {
          asset {
            url
          }
        }
      }
    }
  }
  query {
    allSanityTypography(limit: 1) {
      nodes {
        h1Type {
          ...FontWoffs
        }
        h2Type {
          ...FontWoffs
        }
        h3Type {
          ...FontWoffs
        }
        h4Type {
          ...FontWoffs
        }
        h5Type {
          ...FontWoffs
        }
        h6Type {
          ...FontWoffs
        }
        body1 {
          ...FontWoffs
        }
        body2 {
          ...FontWoffs
        }
        caption {
          ...FontWoffs
        }
        blogHeader {
          ...FontWoffs
        }
      }
    }
  }
`

const generateFontBlock = ({
  name,
  woffUrl,
  woff2Url,
  italic,
  fontWeight,
}: FontBlock) => {
  const styles = css`
    @font-face {
      font-family: "${name}";
      font-style: ${italic ? "italic" : "normal"};
      font-weight: ${fontWeight ?? "normal"};
      src: url(${woff2Url}) format("woff2"), url(${woffUrl}) format("woff");
      font-display: swap;
    }
  `
  const key = `${name}-${fontWeight || 400}-${italic ? "italic" : "regular"}`
  return [
    <link
      key={key}
      rel="preload"
      as="font"
      type="font/woff2"
      crossOrigin="anonymous"
      href={woff2Url}
    />,
    <style id={`${key}-style`} key={`${key}-style`} type="text/css">
      {styles.join("")}
    </style>,
  ]
}

export const SanityTypographyProvider = ({ children }: Props) => {
  const data = useStaticQuery<Query>(staticFontQuery)
  const typographyOptions = data?.allSanityTypography?.nodes?.[0]
  const uniqueFontSets = sanityTypographyCouples?.reduce((acc, couple) => {
    const fontSet = typographyOptions?.[couple.sanityKey]?.typeface
    if (
      fontSet &&
      !acc?.filter(a => a?.fontName === fontSet?.fontName)?.length
    ) {
      return acc?.concat(fontSet)
    } else {
      return acc
    }
  }, [])
  const fontBlocks: FontBlock[] = uniqueFontSets?.reduce((acc, fontSet) => {
    const fontVariants: FontBlock[] = fontSet?.fontVariants?.map(
      (fontVariant: any) => ({
        name: fontSet?.fontName,
        woffUrl: fontVariant?.woff.asset.url,
        woff2Url: fontVariant?.woff2.asset.url,
        fontWeight: fontVariant?.weight,
        italic: fontVariant?.italic,
      })
    )
    return fontVariants && acc?.concat(...fontVariants)
  }, [])
  return (
    <>
      <Helmet>
        {fontBlocks?.map(fontBlock => generateFontBlock(fontBlock))}
      </Helmet>
      {children}
    </>
  )
}
