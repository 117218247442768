module.exports = [{
      plugin: require('../node_modules/gatsby-source-yotpo-shopify/gatsby-browser.js'),
      options: {"plugins":[],"shopName":"staging-pattern-brands","shopifyAccessToken":"a95bc4da044b2c6ceaa2ae6b216f4d85","yotpoAppKey":"m4IsIr8oN8ATtvuJxTqpL0vPyvWezPIShAhoTOEg","appendDefaultObject":true,"createDefaultObject":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Pattern Brands","short_name":"PB","start_url":"/","background_color":"#F0EBE5","theme_color":"#0B474B","display":"minimal-ui","icon":"src/assets/images/pb-favicon-512x512.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"d97e99eb9255f337b1b3f4103cfed2b5"},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-loadable-components-ssr/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
