import { BrandConfig } from "./brand-config"

const config: BrandConfig = {
  favicon: "src/assets/images/pb-favicon-512x512.png",
  checkoutUrl: "check-out.equalparts.com",
  siteUrl: "https://www.equalparts.com",
  siteName: "Equal Parts",
  defaultPrimaryVariantType: "color",
  showGlobalNavigationBar: false,
  shopify: {
    id: "ep",
    name: "equal-parts-integration",
    domain: "check-out.equalparts.com'",
    adminDomain: "equal-parts-integration.myshopify.com",
    token: "ae42c8178574fd15aadd1f95a7f8a38b",
  },
  vendor: {
    sentry: {
      dsn:
        "https://2858cd41f9144ca687d541c671a2b065@o222901.ingest.sentry.io/1371224",
    },
    segment: { key: "NqT2DeY31IaQvQOD8uqhBsarsSymCRrg" },
    yotpo: {
      key: "qUsXNgiAmsnvzJSwW3hONMNEpCpxg8Hh5kFvLeev",
      url: "https://api.yotpo.com/v1/widget/reviews",
      createDefaultObject: true,
    },
    auth0: {
      domain: "account.patternbrands.com",
      clientId: "GBCKPagK1uZH3sVZ2SUaJRHh2XCZp1WY",
    },
    klaviyo: {
      apiKey: "TdX4ZW",
    },
  },
}

export const epConfigs = {
  dev: config,
  production: config,
}
