import { BrandConfigs } from "./brand-config"

export const pbConfigs: BrandConfigs = {
  dev: {
    favicon: "src/assets/images/pb-favicon-512x512.png",
    checkoutUrl: "staging-pattern-brands.myshopify.com",
    siteUrl: "https://www.staging.patternbrands.co",
    siteName: "Pattern Brands",
    showGlobalNavigationBar: true,
    shopify: {
      id: "pb",
      name: "staging-pattern-brands",
      domain: "staging-pattern-brands.myshopify.com",
      adminDomain: "staging-pattern-brands.myshopify.com",
      token: "a95bc4da044b2c6ceaa2ae6b216f4d85",
    },
    vendor: {
      segment: { key: "a87Mm6BBEwq9Ky4D1azBEdREGrHAL4xq" },
      yotpo: {
        key: "m4IsIr8oN8ATtvuJxTqpL0vPyvWezPIShAhoTOEg",
        url: "https://api.yotpo.com/v1/widget/reviews",
        appendDefaultObject: true,
      },
      commissionJunction: {
        enterpriseId: "1564188",
        scriptId: "261707006459",
      },
      auth0: {
        domain: "accounts-staging.patternbrands.co",
        clientId: "KgIwe3gEHCkd1KI9epdfHnNlG6beprF9",
      },
      klaviyo: {
        apiKey: "TdX4ZW",
      },
      google: {
        optimize: {
          key: "OPT-W66FK8P",
        },
      },
    },
    defaultPrimaryVariantType: "size",
  },
  production: {
    favicon: "src/assets/images/pb-favicon-512x512.png",
    checkoutUrl: "checkout.patternbrands.com",
    siteUrl: "https://www.patternbrands.com",
    siteName: "Pattern Brands",
    showGlobalNavigationBar: true,
    upsellVendors: ["open spaces", "equal parts", "gir", "letterfolk"],
    shopify: {
      id: "pb",
      name: "pattern-brands",
      domain: "checkout.patternbrands.com",
      adminDomain: "pattern-brands.myshopify.com",
      token: "d78732735483f7a2277264db73129407",
    },
    vendor: {
      sentry: {
        dsn:
          "https://c2b7be6110054b9abaf616a773425e43@o222901.ingest.sentry.io/2638944",
      },
      segment: { key: "Dl8COX3MKDTQBruh0j9YluEcRA4FkeKX" },
      yotpo: {
        key: "yD7amBrG4uHEMmkxo7dZyckP8q9rwOsu9P2NL4Ld",
        url: "https://api.yotpo.com/v1/widget/reviews",
      },
      auth0: {
        domain: "account.patternbrands.com",
        clientId: "GBCKPagK1uZH3sVZ2SUaJRHh2XCZp1WY",
      },
      klaviyo: {
        apiKey: "TdX4ZW",
      },
      google: {
        optimize: {
          key: "OPT-T7CWG52",
        },
      },
    },
    defaultPrimaryVariantType: "size",
  },
}
