import { SanityVariantOption } from "typings/graphql"

export type Tile = {
  x: number
  y: number
  displayTile: boolean
  disableTile: boolean
  hexCode?: string
}

export type TileVariantColor = {
  name: string
  hexCode: string
  variant: SanityVariantOption
  availableForSale: boolean
  price: number
}

export enum TileMatShapeTypes {
  Rectangle = "Rectangle",
  HalfMoon = "HalfMoon",
}

export type TileMatDimensions = {
  title: string
  width: number
  height: number
  shape: TileMatShapeTypes
}

export type TileDimensions = {
  diam: number
  border: number
  radius: number
  canvasWidth: number
  canvasHeight: number
}

export type TileMatUserAction = {
  tileMatDimensions: TileMatDimensions
  colorSettings: TileMatColorSettings
  tiles: Tile[][]
}

export type TileMatColorSettings = {
  name: string
  backgroundColorHex: string
  defaultDrawColorHex: string
  tileColorHex: string
}

export type Tiles = Tile[][]
