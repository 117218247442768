import { BrandConfigs } from "./brand-config"

export const pkConfigs: BrandConfigs = {
  dev: {
    favicon: "src/assets/images/pk-favicon-512x512.png",
    checkoutUrl: "checkout.poketo.com",
    siteUrl: "https://www.poketo.com",
    siteName: "POKETO",
    showGlobalNavigationBar: true,
    shopify: {
      id: "pk",
      name: "poketo",
      domain: "poketo.myshopify.com",
      adminDomain: "poketo.myshopify.com",
      token: "f27922c2d1b76b49423d195213d10591",
    },
    vendor: {
      segment: { key: "N6pBJwvgGYndoFAadnGrBE72pWPrKuGz" },
      yotpo: {
        key: "eVdd7LysvivIL3j9VLOpZsf8RBexi7XVXvYbKUuu",
        url: "https://api.yotpo.com/v1/widget/reviews",
        appendDefaultObject: true,
      },
      auth0: {
        domain: "accounts-staging.patternbrands.co",
        clientId: "KgIwe3gEHCkd1KI9epdfHnNlG6beprF9",
      },
      klaviyo: {
        apiKey: "Ppe2rM",
      },
    },
    defaultPrimaryVariantType: "size",
  },
  production: {
    favicon: "src/assets/images/pk-favicon-512x512.png",
    checkoutUrl: "checkout.poketo.com",
    siteUrl: "https://www.poketo.com",
    siteName: "POKETO",
    showGlobalNavigationBar: true,
    shopify: {
      id: "pk",
      name: "poketo",
      domain: "checkout.poketo.com",
      adminDomain: "poketo.myshopify.com",
      token: "f27922c2d1b76b49423d195213d10591",
    },
    vendor: {
      segment: { key: "N6pBJwvgGYndoFAadnGrBE72pWPrKuGz" },
      commissionJunction: {
        enterpriseId: "", // todo
        scriptId: "", // todo
      },
      yotpo: {
        key: "eVdd7LysvivIL3j9VLOpZsf8RBexi7XVXvYbKUuu",
        url: "https://api.yotpo.com/v1/widget/reviews",
        appendDefaultObject: true,
      },
      auth0: {
        domain: "account.patternbrands.com",
        clientId: "ir3dXxkLiYod5Lm9LGsSiHRtmJnz2Ifg",
      },
      klaviyo: {
        apiKey: "Ppe2rM",
      },
    },
    defaultPrimaryVariantType: "size",
  },
}
