import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { Notice } from "typings/modules"

type QueueIds = { queueIds: string[] }
type DiscountLoading = { discountLoading: boolean }
type ProductState = QueueIds & Notice & DiscountLoading

const initialState: ProductState = {
  queueIds: [],
  notice: "",
  noticeTheme: "success",
  discountLoading: false,
}

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    updateQueueIds: (state, action: PayloadAction<QueueIds>) => {
      state.queueIds = action.payload.queueIds
    },
    updateNotice: (state, action: PayloadAction<Notice>) => {
      const { notice, noticeTheme } = action.payload
      state.notice = notice
      state.noticeTheme = noticeTheme || "success"
    },
    updateDiscountLoading: (state, action: PayloadAction<DiscountLoading>) => {
      state.discountLoading = action.payload.discountLoading
    },
  },
})

export const {
  updateQueueIds,
  updateNotice,
  updateDiscountLoading,
} = cartSlice.actions

export const cartReducer = cartSlice.reducer
