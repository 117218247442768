exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-account-deleted-tsx": () => import("./../../../src/pages/account-deleted.tsx" /* webpackChunkName: "component---src-pages-account-deleted-tsx" */),
  "component---src-pages-account-tsx": () => import("./../../../src/pages/account/[...].tsx" /* webpackChunkName: "component---src-pages-account-tsx" */),
  "component---src-pages-add-tsx": () => import("./../../../src/pages/add.tsx" /* webpackChunkName: "component---src-pages-add-tsx" */),
  "component---src-pages-cart-tsx": () => import("./../../../src/pages/cart.tsx" /* webpackChunkName: "component---src-pages-cart-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-search-tsx": () => import("./../../../src/pages/search.tsx" /* webpackChunkName: "component---src-pages-search-tsx" */),
  "component---src-templates-blog-category-template-tsx": () => import("./../../../src/templates/BlogCategoryTemplate.tsx" /* webpackChunkName: "component---src-templates-blog-category-template-tsx" */),
  "component---src-templates-blog-post-template-tsx": () => import("./../../../src/templates/BlogPostTemplate.tsx" /* webpackChunkName: "component---src-templates-blog-post-template-tsx" */),
  "component---src-templates-blog-tag-template-tsx": () => import("./../../../src/templates/BlogTagTemplate.tsx" /* webpackChunkName: "component---src-templates-blog-tag-template-tsx" */),
  "component---src-templates-page-template-tsx": () => import("./../../../src/templates/PageTemplate.tsx" /* webpackChunkName: "component---src-templates-page-template-tsx" */),
  "component---src-templates-product-template-tsx": () => import("./../../../src/templates/ProductTemplate.tsx" /* webpackChunkName: "component---src-templates-product-template-tsx" */)
}

