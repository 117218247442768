import React, { ReactNode, useEffect } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Helmet } from "react-helmet"
import { Query } from "typings/graphql"
import { Config } from "config/brands"

type Props = {
  children: ReactNode
}

const staticScripsQuery = graphql`
  query {
    sanityGlobalSettings {
      metaFields {
        _key
        name
        content
      }
      scripts {
        _key
        _rawScriptContent
        location
        src
      }
    }
  }
`

export const ScriptsProvider = ({ children }: Props) => {
  const data = useStaticQuery<Query>(staticScripsQuery)
  const globalSettings = data?.sanityGlobalSettings
  const scripts = globalSettings?.scripts
  const metaFields = globalSettings?.metaFields
  const headScripts = scripts?.filter(script => script?.location === "head")
  useEffect(() => {
    scripts?.forEach(script => {
      const src = script?.src
      const content = script?._rawScriptContent?.[0]?.children?.[0]?.text
      const key = script?._key
      const alreadyRendered = document.getElementById(key)
      if (alreadyRendered) {
        return
      }

      const scriptTag = document.createElement("script")
      scriptTag.defer = true
      scriptTag.async = true
      scriptTag.id = key
      src && (scriptTag.src = src)
      content && (scriptTag.text = content)
      script?.location === "preBody" && document.body.prepend(scriptTag)
      script?.location === "postBody" && document.body.append(scriptTag)
    })
  }, [])

  const cj = Config.vendor.commissionJunction

  return (
    <>
      <Helmet>
        {cj && (
          <script type="text/javascript">
            {`if (!window.cj) window.cj = {}; cj.sitePage = {enterpriseId: ${cj.enterpriseId}}; if (document.location.search.includes('cjevent=')) {(function(a,b,c,d){ a='https://www.mczbf.com/tags/${cj.scriptId}/tag.js'; b=document;c='script';d=b.createElement(c);d.src=a; d.type='text/java'+c;d.async=true; d.id='cjapitag'; a=b.getElementsByTagName(c)[0];a.parentNode.insertBefore(d,a) })();}`}
          </script>
        )}
        {headScripts &&
          headScripts.map(script => {
            const src = script?.src
            const content = script?._rawScriptContent?.[0]?.children?.[0]?.text
            const key = script?._key
            return src ? (
              <script key={key} src={src} defer={true}></script>
            ) : (
              <script key={key}>{content && content}</script>
            )
          })}
        {metaFields &&
          metaFields.map(meta => {
            const name = meta?.name
            const content = meta?.content
            const key = meta?._key
            return (
              <meta
                key={key}
                name={name && name}
                content={content && content}
              />
            )
          })}
      </Helmet>
      {children}
    </>
  )
}
