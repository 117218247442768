import React from "react"
import { GatsbyBrowser } from "gatsby"
import { Provider } from "react-redux"
import { store } from "redux/store"
import Cookie from "js-cookie"
import { Config } from "config/brands/index"
import { SanityTypographyProvider } from "components/Providers/SanityTypographyProvider"
import { ScriptsProvider } from "components/Providers/ScriptsProvider"
import { VariantSettingsProvider } from "contexts/VariantSettingsContext"
import smoothscroll from "smoothscroll-polyfill"
import { waitFor } from "utils/timeoutUtils"

if (typeof window !== `undefined`) {
  smoothscroll.polyfill()
}

export const wrapRootElement: GatsbyBrowser["wrapRootElement"] = ({
  element,
}) => (
  <SanityTypographyProvider>
    <ScriptsProvider>
      <Provider store={store}>
        <VariantSettingsProvider>
          <>{element}</>
        </VariantSettingsProvider>
      </Provider>
    </ScriptsProvider>
  </SanityTypographyProvider>
)

export const shouldUpdateScroll: GatsbyBrowser["shouldUpdateScroll"] = ({
  routerProps,
  prevRouterProps,
}) => {
  const newLocation = routerProps.location
  const prevLocation = prevRouterProps?.location
  const containsVariant = newLocation.search.indexOf("variant") > -1
  if (containsVariant && newLocation.pathname === prevLocation?.pathname) {
    return false
  }
  return true
}

export const onRouteUpdate: GatsbyBrowser["onRouteUpdate"] = ({
  location,
  prevLocation,
}) => {
  const affiliateCookieName = "cjevent"
  const url = document.location.search
  const params = new URLSearchParams(url)
  const cjEvent = params.get(affiliateCookieName)
  if (cjEvent) {
    Cookie.remove(affiliateCookieName)
    Cookie.set(affiliateCookieName, cjEvent, {
      expires: 7,
      domain: Config.siteUrl.replace(/^(?:https?:\/\/)?(?:www\.)?/i, ""),
    })
  }

  if (location.pathname !== prevLocation?.pathname) {
    waitFor(() => !!window.analytics).then(() => {
      window.analytics.page()
    })
  }
}
