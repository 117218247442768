import { BrandConfigs } from "./brand-config"

export const girConfigs: BrandConfigs = {
  dev: {
    favicon: "src/assets/images/gir-favicon-512x512.png",
    checkoutUrl: "staging-getitright-dev.myshopify.com",
    siteUrl: "https://gir.staging.patternbrands.co/",
    siteName: "GIR",
    showGlobalNavigationBar: true,
    shopify: {
      id: "gir",
      name: "staging-getitright-dev",
      domain: "staging-getitright-dev.myshopify.com",
      adminDomain: "staging-getitright-dev.myshopify.com",
      token: "7c380fbfcec7437e293bcdb05e26c90d",
    },
    vendor: {
      segment: { key: "Dl0VicIuMmEouaE7rrkqn6hakxbJfghR" },
      yotpo: {
        key: "eQY4QLCTcCYthiSqwM0NP3a5QOmVFagAjpgypSu1",
        url: "https://api.yotpo.com/v1/widget/reviews",
        createDefaultObject: true,
      },
      auth0: {
        domain: "accounts-staging.patternbrands.co",
        clientId: "DNErsPP6shxQqBFcKEpJHFTRS0PmACYG",
      },
      klaviyo: {
        apiKey: "KK4mXZ",
      },
    },
    defaultPrimaryVariantType: "size",
  },
  production: {
    favicon: "src/assets/images/gir-favicon-512x512.png",
    checkoutUrl: "checkout.gir.co",
    siteUrl: "https://www.gir.co",
    siteName: "GIR",
    showGlobalNavigationBar: true,
    shopify: {
      id: "gir",
      name: "getitright-dev",
      domain: "checkout.gir.co",
      adminDomain: "getitright-dev.myshopify.com",
      token: "3ad0e8fb3ff54aabeb8315e4b4d9e926",
    },
    vendor: {
      sentry: {
        dsn:
          "https://5a87fdfa97bd49bbbffeeb0c908098a3@o222901.ingest.sentry.io/6112145",
      },
      segment: { key: "jQ6UHDS000APDBUH8rmVtie03tROumfN" },
      commissionJunction: {
        enterpriseId: "1564189",
        scriptId: "261695253405",
      },
      yotpo: {
        key: "eQY4QLCTcCYthiSqwM0NP3a5QOmVFagAjpgypSu1",
        url: "https://api.yotpo.com/v1/widget/reviews",
      },
      auth0: {
        domain: "account.patternbrands.com",
        clientId: "6gvbFITNeuCscPa9HdAiUmrG0LhLmum5",
      },
      klaviyo: {
        apiKey: "KK4mXZ",
      },
    },
    defaultPrimaryVariantType: "size",
  },
}
