import { SelectColorOption } from "components/ColorOptions/ColorSelector"
import { VariantOptionWithId } from "typings/modules"
import { LetterBoardSizes, LetterBoardType } from "./LetterBoard.types"

import BlackFelt from "../../assets/images/LetterBoard/felts/black.jpg"
import GrayFelt from "../../assets/images/LetterBoard/felts/gray.jpg"
import WhiteFelt from "../../assets/images/LetterBoard/felts/white.jpg"
import MetalFrame from "../../assets/images/LetterBoard/frames/metal.jpg"
import OakFrame from "../../assets/images/LetterBoard/frames/oak.jpg"

export const DPI = 56

export const letterBoardColorSchemes = {
  blackMetal: "Black / Metal",
  whiteOak: "White / Oak",
  grayOak: "Gray / Oak",
  blackOak: "Black / Oak",
}

export const letterBoardFelts = {
  [letterBoardColorSchemes.blackMetal]: BlackFelt,
  [letterBoardColorSchemes.blackOak]: BlackFelt,
  [letterBoardColorSchemes.grayOak]: GrayFelt,
  [letterBoardColorSchemes.whiteOak]: WhiteFelt,
}

export const letterBoardFrames = {
  [letterBoardColorSchemes.blackMetal]: MetalFrame,
  [letterBoardColorSchemes.blackOak]: OakFrame,
  [letterBoardColorSchemes.grayOak]: OakFrame,
  [letterBoardColorSchemes.whiteOak]: OakFrame,
}

export const letterBoardTypes: LetterBoardType[] = [
  "poet",
  "writer",
  "copywriter",
  "wordsmith",
]

export const letterBoardSizes: LetterBoardSizes = {
  poet: { width: 10, height: 10 },
  writer: { width: 16, height: 20 },
  wordsmith: { width: 24, height: 30 },
  copywriter: { width: 20, height: 16 },
}

export const letterSizes: VariantOptionWithId[] = [
  { id: "0", name: '1/2"', value: "2" },
  { id: "1", name: '3/4"', value: "3" },
  { id: "2", name: '1"', value: "4" },
  { id: "3", name: '2"', value: "8" },
]

export const letterBoardColors: SelectColorOption[] = [
  {
    name: letterBoardColorSchemes.blackMetal,
    swatchOrOpacityHex: ["#393939", "#D9D8D8", "black"],
  },
  {
    name: letterBoardColorSchemes.whiteOak,
    swatchOrOpacityHex: ["#FCF9F4", "#E4BB94", "white"],
  },
  {
    name: letterBoardColorSchemes.grayOak,
    swatchOrOpacityHex: ["#D9D8D8", "#E4BB94", "black"],
  },
  {
    name: letterBoardColorSchemes.blackOak,
    swatchOrOpacityHex: ["#393939", "#E4BB94", "black"],
  },
]
