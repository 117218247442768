import { configureStore, getDefaultMiddleware, Action } from "@reduxjs/toolkit"
import { ThunkAction } from "redux-thunk"
import rootReducer, { RootState } from "./rootReducer"

export const store = configureStore({
  reducer: rootReducer,
  middleware: [...getDefaultMiddleware({ serializableCheck: false })],
})

export type AppDispatch = typeof store.dispatch
export type AppThunk = ThunkAction<void, RootState, null, Action<string>>
export type FetchAble = { loading: boolean; error: string | null }
