import { useStaticQuery } from "gatsby"
import { graphql } from "gatsby"
import React, { useState, createContext, ReactNode } from "react"
import { Query, SanityVariantTypeComponent } from "typings/graphql"
import {
  SelectedOptionsKey,
  AvailableOptionPairs,
  VariantOptionWithIdPair,
} from "typings/modules"

const variantSettingsQuery = graphql`
  query {
    sanityGlobalSettings {
      variantTypeComponents {
        variantTypeName
        component
      }
    }
  }
`

type VariantTypeComponentPredicate = (type: SelectedOptionsKey) => boolean

type VariantSettingsContextProps = {
  variantComponents: SanityVariantTypeComponent[]
  shouldUseSwatches: VariantTypeComponentPredicate
  shouldUseRadio: VariantTypeComponentPredicate
  shouldUseDropdown: VariantTypeComponentPredicate
  getFirstSwatchTypeName: (
    availableOptions: AvailableOptionPairs
  ) => SelectedOptionsKey
  getFirstSwatchOptions: (
    availableOptions: AvailableOptionPairs
  ) => VariantOptionWithIdPair[]
}

export const VariantSettingsContext = createContext<VariantSettingsContextProps>(
  {} as VariantSettingsContextProps
)

type VariantSettingsProviderProps = {
  children: ReactNode
}

export const VariantSettingsProvider = ({
  children,
}: VariantSettingsProviderProps) => {
  const { sanityGlobalSettings } = useStaticQuery<Query>(variantSettingsQuery)
  const initialComponents = sanityGlobalSettings?.variantTypeComponents
  const [variantComponents] = useState<SanityVariantTypeComponent[]>(
    initialComponents
  )
  const shouldUseSwatches = (type: SelectedOptionsKey) =>
    variantComponents.find(vc => vc.variantTypeName === type)?.component ===
    "swatches"
  const shouldUseRadio = (type: SelectedOptionsKey) => {
    const componentChosen = variantComponents.find(
      vc => vc.variantTypeName === type
    )
    return (
      componentChosen === undefined || componentChosen.component === "radio"
    )
  }
  const shouldUseDropdown = (type: SelectedOptionsKey) =>
    variantComponents.find(vc => vc.variantTypeName === type)?.component ===
    "dropdown"
  const getFirstSwatchTypeName = (
    availableOptions: AvailableOptionPairs
  ): SelectedOptionsKey | null | undefined => {
    if (!availableOptions) return null
    const availableTypes = Object.keys(availableOptions)
    return availableTypes.find(type => shouldUseSwatches(type))
  }
  const getFirstSwatchOptions = (
    availableOptions: AvailableOptionPairs
  ): VariantOptionWithIdPair[] | null | undefined => {
    if (!availableOptions) return null
    const colorTypeName = getFirstSwatchTypeName(availableOptions)
    if (!colorTypeName) return null
    return availableOptions[colorTypeName]
  }

  return (
    <VariantSettingsContext.Provider
      value={{
        variantComponents,
        shouldUseSwatches,
        shouldUseRadio,
        shouldUseDropdown,
        getFirstSwatchTypeName,
        getFirstSwatchOptions,
      }}
    >
      {children}
    </VariantSettingsContext.Provider>
  )
}

export const VariantSettingsConsumer = VariantSettingsContext.Consumer
