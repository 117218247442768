import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { SelectColorOption } from "components/ColorOptions/ColorSelector"
import {
  letterBoardColors,
  letterBoardSizes,
  letterSizes,
  DPI,
} from "components/LetterBoard/LetterBoard.constants"
import {
  CanvasDimensions,
  LetterBoardType,
  TextAlignmentType,
} from "components/LetterBoard/LetterBoard.types"
import { VariantOptionWithId } from "typings/modules"

type LetterBoardDesignToolState = {
  userText: string
  letterSize: VariantOptionWithId
  lineSpacing: number
  letterBoardSize: LetterBoardType
  color: SelectColorOption
  textAlignment: TextAlignmentType
  containerWidth?: number
  canvasDimensions?: CanvasDimensions
}

const initialState: LetterBoardDesignToolState = {
  userText: "",
  letterSize: letterSizes[1],
  lineSpacing: 5,
  letterBoardSize: "poet",
  color: letterBoardColors[0],
  textAlignment: "center",
  containerWidth: null,
  canvasDimensions: null,
}

const letterBoardDesignToolSlice = createSlice({
  name: "letterBoardDesignTool",
  initialState,
  reducers: {
    initialize: (
      state,
      action: PayloadAction<{
        containerWidth: number
      }>
    ) => {
      const { containerWidth } = action.payload

      state.containerWidth = containerWidth

      initCanvas(state)
    },
    changeColor: (
      state,
      action: PayloadAction<{ color: SelectColorOption }>
    ) => {
      state.color = action.payload.color
    },
    changeText: (state, action: PayloadAction<{ userText: string }>) => {
      state.userText = action.payload.userText
    },
    changeLetterSize: (
      state,
      action: PayloadAction<{ letterSize: VariantOptionWithId }>
    ) => {
      state.letterSize = action.payload.letterSize
    },
    changeLineSpacing: (
      state,
      action: PayloadAction<{ lineSpacing: number }>
    ) => {
      state.lineSpacing = action.payload.lineSpacing
    },
    changeLetterBoardSize: (
      state,
      action: PayloadAction<{ letterBoardSize: LetterBoardType }>
    ) => {
      state.letterBoardSize = action.payload.letterBoardSize
    },
    changeTextAlignment: (
      state,
      action: PayloadAction<{ textAlignment: TextAlignmentType }>
    ) => {
      state.textAlignment = action.payload.textAlignment
    },
  },
})

const initCanvas = (state: LetterBoardDesignToolState) => {
  const { containerWidth, letterBoardSize } = state
  const letterBoardWidth = letterBoardSizes[letterBoardSize].width
  const letterBoardHeight = letterBoardSizes[letterBoardSize].height
  const aspect = letterBoardWidth / letterBoardHeight
  const nominalWidth = letterBoardWidth * DPI
  const displayWidth = Math.min(nominalWidth, containerWidth)
  const displayHeight = displayWidth / aspect
  const fullWidth = Math.round(displayWidth)
  const fullHeight = Math.round(displayHeight)
  const shrink = displayWidth / nominalWidth
  const lineHeight = (DPI / 4) * shrink
  const frameSize = lineHeight * 2

  const canvasDimensions = {
    displayWidth,
    displayHeight,
    fullWidth,
    fullHeight,
    shrink,
    lineHeight,
    frameSize,
  }

  state.canvasDimensions = canvasDimensions
}

export const {
  initialize,
  changeColor,
  changeText,
  changeLetterBoardSize,
  changeLetterSize,
  changeLineSpacing,
  changeTextAlignment,
} = letterBoardDesignToolSlice.actions

export const letterBoardDesignToolReducer = letterBoardDesignToolSlice.reducer
