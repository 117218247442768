import { combineReducers } from "@reduxjs/toolkit"
import { cartReducer } from "./cart"
import { tileMatToolReducer } from "./tileMat"
import { letterBoardDesignToolReducer } from "./letterBoard"
import { headerReducer } from "./header"
import { notificationReducer } from "./notification"

const rootReducer = combineReducers({
  cart: cartReducer,
  tileMatTool: tileMatToolReducer,
  letterBoardDesignTool: letterBoardDesignToolReducer,
  header: headerReducer,
  notification: notificationReducer,
})

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer
