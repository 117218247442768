import { BrandConfigs } from "./brand-config"

export const osConfigs: BrandConfigs = {
  dev: {
    favicon: "src/assets/images/os-favicon-512x512.png",
    checkoutUrl: "staging-open-spaces.myshopify.com",
    siteUrl: "https://www.os.staging.patternbrands.co",
    siteName: "Open Spaces",
    showGlobalNavigationBar: true,
    shopify: {
      id: "os",
      name: "staging-open-spaces",
      domain: "staging-open-spaces.myshopify.com",
      adminDomain: "staging-open-spaces.myshopify.com",
      token: "f3ae8f49fb9f95d457267df187e205a4",
    },
    vendor: {
      segment: { key: "Bpv4yPpdMMSKYufS4CEt9rwJJPaJ08ZT" },
      yotpo: {
        key: "ZeRrKfF4nkqsine9j2IVgdGOmpHCvbVu9c3GjpUt",
        url: "https://api.yotpo.com/v1/widget/reviews",
        createDefaultObject: true,
      },
      auth0: {
        domain: "accounts-staging.patternbrands.co",
        clientId: "KgIwe3gEHCkd1KI9epdfHnNlG6beprF9",
      },
      klaviyo: {
        apiKey: "Xt98HH",
      },
    },
    defaultPrimaryVariantType: "color",
  },
  production: {
    favicon: "src/assets/images/os-favicon-512x512.png",
    checkoutUrl: "checkout.getopenspaces.com",
    siteUrl: "https://www.getopenspaces.com",
    siteName: "Open Spaces",
    showGlobalNavigationBar: true,
    shopify: {
      id: "os",
      name: "prod-open-spaces",
      domain: "checkout.getopenspaces.com",
      adminDomain: "prod-open-spaces.myshopify.com",
      token: "6f4d28cddc7077ed29869ce6a6da42de",
    },
    vendor: {
      sentry: {
        dsn:
          "https://ef6e233b7e3b433bab2a0aca2eeb9b8b@o222901.ingest.sentry.io/1760576",
      },
      segment: { key: "M8dW3iTtIZQiVE3O0VhWlxHpD4pYBUDn" },
      yotpo: {
        key: "I2XWQdePPqFqWU6Z3Q2RlotGwjgbiN2OogNMtYZR",
        url: "https://api.yotpo.com/v1/widget/reviews",
      },
      auth0: {
        domain: "account.patternbrands.com",
        clientId: "DSsIMIUTSQWtWLVT8MXGWjW36mMFzkBw",
      },
      klaviyo: {
        apiKey: "Xt98HH",
      },
    },
    defaultPrimaryVariantType: "color",
  },
}
