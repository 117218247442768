import { TileMatDimensions, TileMatShapeTypes } from "./TileMat.types"

export const MAX_UNDOS_ALLOWED = 20
export const HALF_MOON_CIRCLE_CENTER_HEIGHT = 13
export const HALF_MOON_CIRCLE_STROKE_WIDTH = 0.55

export const smallMatDimensions = {
  title: "Regular",
  height: 17,
  width: 33,
  shape: TileMatShapeTypes.Rectangle,
}
export const largeMatDimensions = {
  title: "Large",
  height: 20,
  width: 45,
  shape: TileMatShapeTypes.Rectangle,
}
export const halfMoonMatDimensions = {
  title: "Half Moon",
  height: 21,
  width: 45,
  shape: TileMatShapeTypes.HalfMoon,
}

export const tileMatSizes: TileMatDimensions[] = [
  smallMatDimensions,
  largeMatDimensions,
  halfMoonMatDimensions,
]
