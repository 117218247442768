import { BrandConfigs } from "./brand-config"

export const ydConfigs: BrandConfigs = {
  dev: {
    favicon: "src/assets/images/yd-favicon-512x512.png",
    checkoutUrl: "checkout.yielddesign.co",
    siteUrl: "https://www.yielddesign.co",
    siteName: "YIELD",
    showGlobalNavigationBar: true,
    shopify: {
      id: "yd",
      name: "yield-design-llc",
      domain: "yield-design-llc.myshopify.com",
      adminDomain: "yield-design-llc.myshopify.com",
      token: "bde4aef5bae5a75de947b4edb8a69762",
    },
    vendor: {
      segment: { key: "2W5VbGvDuzoeSfLQh2ozVXkEBH3tPocW" },
      yotpo: {
        key: "VEaZTa3ecictGXz39HQ5oK5HZWonNi3hUCs9tDt6",
        url: "https://api.yotpo.com/v1/widget/reviews",
        createDefaultObject: true,
      },
      auth0: {
        domain: "accounts-staging.patternbrands.co",
        clientId: "KgIwe3gEHCkd1KI9epdfHnNlG6beprF9",
      },
      klaviyo: {
        apiKey: "Tdf9rt",
      },
    },
    defaultPrimaryVariantType: "size",
  },
  production: {
    favicon: "src/assets/images/yd-favicon-512x512.png",
    checkoutUrl: "checkout.yielddesign.co",
    siteUrl: "https://www.yielddesign.co",
    siteName: "YIELD",
    showGlobalNavigationBar: true,
    shopify: {
      id: "yd",
      name: "yield-design-llc",
      domain: "checkout.yielddesign.co",
      adminDomain: "yield-design-llc.myshopify.com",
      token: "bde4aef5bae5a75de947b4edb8a69762",
    },
    vendor: {
      segment: { key: "2W5VbGvDuzoeSfLQh2ozVXkEBH3tPocW" },
      commissionJunction: {
        enterpriseId: "1564844",
        scriptId: "413810320865",
      },
      yotpo: {
        key: "VEaZTa3ecictGXz39HQ5oK5HZWonNi3hUCs9tDt6",
        url: "https://api.yotpo.com/v1/widget/reviews",
        createDefaultObject: true, // remove for launch
      },
      auth0: {
        domain: "account.patternbrands.com",
        clientId: "8MbrjZOB0dpwmvArogstJqsG34qiASXl",
      },
      klaviyo: {
        apiKey: "Tdf9rt",
      },
    },
    defaultPrimaryVariantType: "size",
  },
}
